<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <form @keydown="form.onKeydown($event)" @submit.prevent="editPartner" v-if="partner">

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="name">
              Имя компании <span class="req"/>
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('name') }">
              <input class="form-control"
                     id="name"
                     name="name"
                     required
                     type="text"
                     v-model="form.name">
              <HasError :form="form" field="name"/>
            </div>
          </div>
        </div>
        
        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="client_name">
              Имя контактного лица <span class="req"/>
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('client_name') }">
              <input class="form-control"
                     id="client_name"
                     name="client_name"
                     required
                     type="text"
                     v-model="form.client_name">
              <HasError :form="form" field="client_name"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="client_phone">
              Телефон контактного лица <span class="req"/>
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('client_phone') }">
              <vue-tel-input id="client_phone"
                             name="client_phone"
                             v-bind='phone_config'
                             v-model="form.client_phone">
              </vue-tel-input>
              <HasError :form="form" field="client_phone"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone">
              Телефон компании <span class="req"/>
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone') }">
              <vue-tel-input id="phone"
                             name="phone"
                             v-bind='phone_config'
                             v-model="form.phone">
              </vue-tel-input>
              <HasError :form="form" field="phone"/>
            </div>
          </div>
        </div>

        <div class="form-group text-right">
          <input class="pointer"
                 id="is_approved"
                 :checked="form.is_approved"
                 style="transform: scale(1.2);"
                 type="checkbox"
                 v-model="form.is_approved">
          <label for="is_approved" class="font-medium margin-l-5 padding-l-10">Одобрен</label>
        </div>

        <br/>

        <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

        <Button :disabled="!validateData || form.busy"
                :form="form"
                :loading="form.busy"
                class="btn btn-lg btn-warning pull-right"
                style="min-width: 100px;">
          <i class="fa fa-edit"></i>
        </Button>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import Multiselect from 'vue-multiselect';
import {VueTelInput} from 'vue-tel-input';
import phone_config from './../../assets/data/vue-tel-input-config.json';

Form.axios = API.apiClient;

export default {
  name: 'edit',

  components: {
    VueTelInput,
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: [
    'partnerId',
  ],

  data: () => ({
    page_title: 'Изменить партнера',

    form: null,

    partner: null,

    phone_config: phone_config,
  }),

  async created() {
    try {
      const partner_response = await API.apiClient.get('/partner/' + this.partnerId);

      this.partner = partner_response.data;

      this.partner.client_phone = '+' + this.partner.client_phone.toString();
      this.partner.phone = '+' + this.partner.phone.toString();

      this.fillPartnerForm();
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {
    validateData: function () {
      return this.form.name && this.form.phone;
    },
  },

  methods: {
    fillPartnerForm() {
      try {
        this.form = new Form({
          name: this.partner.name,
          client_name: this.partner.client_name,
          client_phone: this.partner.client_phone,
          phone: this.partner.phone,
          is_approved: this.partner.is_approved,
        });
      } catch (error) {
        errorHandler(error);
      }
    },

    async editPartner() {
      await this.form.put('/partners/edit/' + this.partnerId).then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/partners'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
